
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import PessoaModal from '@/views/Cadastros/Pessoas/PessoaModal.vue';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import ServicoTransportadora from '@/servicos/Cadastros/Pessoas/ServicoTransportadora';
import { ETipoRelacaoPessoa } from '@/models/Enumeradores/Cadastros/Pessoas/ETipoRelacaoPessoa';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';

export default defineComponent({
  name: 'Transportadora',
  components: {
    TelaPadraoCrud,
    PessoaModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();

    const refTelaPadraoCrud = ref<InstanceType<typeof TelaPadraoCrud>>();

    const servicoTransportadora = new ServicoTransportadora();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Nova';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão da transportadora';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ordering: true,
      },
      {
        title: 'CPF/CNPJ', dataIndex: 'cpfCnpj', key: 'CpfCnpj', position: 2, width: 150, visible: true, ordering: false, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.MascararCPFCNPJ,
      },
      {
        title: 'Nome/Razão Social', dataIndex: 'nomeCompletoRazaoSocial', key: 'NomeCompletoRazaoSocial', position: 3, ellipsis: true, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Nome abreviado/Fantasia', dataIndex: 'nomeAbreviadoNomeFantasia', key: 'NomeAbreviadoNomeFantasia', width: 200, ellipsis: true, position: 4, visible: true, ordering: true,
      },
      {
        title: 'E-mail', dataIndex: 'email', key: 'EmailPessoa', position: 5, ellipsis: true, visible: true, ordering: true,
      },
      {
        title: 'Telefone', dataIndex: 'telefone', key: 'TelefonePessoa', position: 6, width: 130, visible: true, ordering: true, customRenderRow: ECustomRenderRow.MascararTelefoneCelular,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'ativo', position: 7, visible: true, width: 100, align: 'left', customRenderRow: ECustomRenderRow.TagAtivo,
      },
      {
        title: 'Ações', key: 'acoes', position: 8, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'CodigoPessoa', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refTelaPadraoCrud.value !== undefined) {
        await refTelaPadraoCrud.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }

    return {
      crudBase,
      servicoTransportadora,
      ETipoRelacaoPessoa,
      exibirModal,
      sincronizaTelaCrud,
      refTelaPadraoCrud,
      sincronizarRegistro,
    };
  },
});
